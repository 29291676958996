'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { pxToRem } from '@ui/style/muiTheme'
import { Icon32Close } from 'src/assets/icons/Icon32Close'
import Icon32Forward15 from 'src/assets/icons/Icon32Forward15'
import IconPlaySm from 'src/assets/icons/IconPlaySm'

interface AppAodControlsProps {
	paused: boolean
	currentTime: number
	updateCurrentTime: (value: number) => void
	handlePlay: () => void
	onClose: () => void
}
const AppAodControls = ({
	paused,
	currentTime,
	updateCurrentTime,
	handlePlay,
	onClose,
}: AppAodControlsProps) => {
	return (
		<UIFlex
			flexDirection="row"
			alignItems="center"
			flexShrink={0}
			sx={{
				'&>*:not(:first-child)': {
					marginLeft: pxToRem(12),
				},
			}}
		>
			<button type="button" onClick={handlePlay}>
				<IconPlaySm isPlaying={!paused ?? true} />
				<UIText readonly>재생</UIText>
			</button>
			<button type="button" onClick={() => updateCurrentTime(currentTime + 15)}>
				<Icon32Forward15 />
				<UIText readonly>15초 앞으로</UIText>
			</button>
			<button type="button" onClick={onClose}>
				<Icon32Close />
				<UIText readonly>닫기</UIText>
			</button>
		</UIFlex>
	)
}

export default AppAodControls
