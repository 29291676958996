const Icon16More = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="33"
			viewBox="0 0 33 33"
			fill="none"
		>
			<circle
				cx="1.5"
				cy="1.5"
				r="1.5"
				transform="matrix(1 0 0 -1 14.5156 10.9713)"
				fill="#242424"
			/>
			<circle
				cx="1.5"
				cy="1.5"
				r="1.5"
				transform="matrix(1 0 0 -1 14.5156 17.9713)"
				fill="#242424"
			/>
			<circle
				cx="1.5"
				cy="1.5"
				r="1.5"
				transform="matrix(1 0 0 -1 14.5156 24.9713)"
				fill="#242424"
			/>
		</svg>
	)
}

export default Icon16More
