'use client'

const Icon32Forward15 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M20.8596 18.1063C20.8628 17.6602 20.7674 17.2592 20.5722 16.9045C20.3802 16.5499 20.1097 16.2745 19.7614 16.0791C19.4164 15.8806 19.0311 15.7817 18.6066 15.7817C18.3047 15.7817 18.0261 15.837 17.7712 15.948C17.5447 16.0468 17.3624 16.1738 17.2265 16.33L17.4021 14.7278H20.4234H20.4734V14.6778V13.5V13.45H20.4234H16.1747H16.1293L16.125 13.4953L15.7483 17.4721L15.7431 17.5268H15.798H17.0998H17.1298L17.1439 17.5003C17.2447 17.311 17.3794 17.1724 17.5478 17.0822C17.7166 16.9918 17.9255 16.9468 18.1768 16.9499V16.9499H18.1775C18.4113 16.9499 18.6171 17.0021 18.7962 17.1053L18.7962 17.1053L18.7971 17.1058C18.9797 17.2063 19.1207 17.3458 19.221 17.5251L19.221 17.5251L19.2215 17.526C19.3245 17.7016 19.3768 17.9072 19.3768 18.1444C19.3768 18.3719 19.3262 18.5747 19.2259 18.7541C19.1255 18.9303 18.986 19.0684 18.8063 19.1689L18.806 19.1691C18.63 19.2692 18.4288 19.3199 18.2013 19.3199C18.0111 19.3199 17.8355 19.2831 17.6741 19.2101C17.5157 19.1339 17.3872 19.0308 17.2875 18.9011C17.1917 18.7722 17.1361 18.6238 17.121 18.4547L17.1169 18.4091H17.0712H15.6836H15.6323L15.6336 18.4604C15.6434 18.8577 15.7592 19.2169 15.9811 19.5367L15.9813 19.537C16.2062 19.8564 16.5139 20.105 16.9025 20.2832C17.2913 20.4614 17.7245 20.55 18.2013 20.55C18.7199 20.55 19.1809 20.4469 19.5832 20.2393L19.5835 20.2391C19.9853 20.0285 20.2991 19.7378 20.5234 19.3672C20.751 18.9932 20.8628 18.5725 20.8596 18.1063ZM20.8596 18.1063C20.8596 18.1062 20.8596 18.106 20.8596 18.1059L20.8096 18.1063L20.8596 18.1066C20.8596 18.1065 20.8596 18.1064 20.8596 18.1063Z"
				fill="#242424"
				stroke="#242424"
				strokeWidth="0.1"
			/>
			<path
				d="M14.0565 20.4546H14.1065V20.4046V13.5V13.45H14.0565H12.7071H12.6925L12.6802 13.4578L10.9731 14.545L10.95 14.5598V14.5872V15.8651V15.9561L11.0268 15.9073L12.5665 14.9305V20.4046V20.4546H12.6165H14.0565Z"
				fill="#242424"
				stroke="#242424"
				strokeWidth="0.1"
			/>
			<path
				d="M18.375 7.79929C17.6159 7.60391 16.8201 7.5 16 7.5C10.7533 7.5 6.5 11.7533 6.5 17C6.5 22.2467 10.7533 26.5 16 26.5C21.2467 26.5 25.5 22.2467 25.5 17C25.5 15.6489 25.218 14.3637 24.7095 13.2"
				stroke="#242424"
				strokeWidth="1.8"
			/>
			<path d="M15 3.5L19 7.5L15 11.5" stroke="#242424" strokeWidth="1.8" />
		</svg>
	)
}

export default Icon32Forward15
