import { useArticleSizeControl } from '@store/articleSize'
import { useState, useEffect, useLayoutEffect, useRef } from 'react'

/**
 * useIsScrollDown 훅
 * @description  스크롤 이벤트시 스크롤이 up인지 down인지 판별하여 boolean으로 리턴합니다.
 * @returns boolean
 */
const useIsScrollDown = () => {
	const { articleSize } = useArticleSizeControl()
	const [changedSize, setChangedSize] = useState(false)
	const [isScrollDown, setIsScrollDown] = useState(false)
	const prevScrollTop = useRef<number>(0)

	useLayoutEffect(() => {
		setChangedSize(true)
	}, [articleSize])
	useEffect(() => {
		const getScrollDirection = () => {
			const nextScrollTop = window.scrollY || document.documentElement.scrollTop
			const scrollDiff = Math.abs(nextScrollTop - prevScrollTop.current)
			if (scrollDiff > 3) {
				setIsScrollDown(changedSize ? isScrollDown : prevScrollTop.current < nextScrollTop)
			}
			prevScrollTop.current = nextScrollTop <= 0 ? 0 : nextScrollTop
			if (changedSize) {
				setChangedSize(false)
			}
		}
		window.addEventListener('scroll', getScrollDirection)
		return () => window.removeEventListener('scroll', getScrollDirection)
	}, [changedSize, isScrollDown])

	return isScrollDown
}

export default useIsScrollDown
