import { useMutation } from '@tanstack/react-query'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { ApiResultVoProgramAodResponseVo } from '@schemas/non-auth'
import { fetchAPI } from '@utils/http/apiRequest'

export type ProgramAodResponse = CommonResponse<ApiResultVoProgramAodResponseVo>

export interface ProgramAodReqModel {
	programIdx: string
}

const path = '/restapi/v1/get/contents/program/aod'
const queryKeyString = (props?: ProgramAodReqModel) => ['@contents/program/aod', props] as const

const getQuery = async (props: ProgramAodReqModel) => {
	const { programIdx } = props
	const params = new URLSearchParams({
		...(programIdx ? { programIdx: String(programIdx) } : {}),
	})
	return fetchAPI({ path, params })
}

export const queryProgramAod = (props: ProgramAodReqModel) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props),
})

export const useProgramAodMutation = () => {
	const { data, ...rest } = useMutation<
		ApiResultVoProgramAodResponseVo,
		Error,
		ProgramAodReqModel
	>({
		mutationKey: queryKeyString(),
		mutationFn: (props: ProgramAodReqModel) => getQuery(props),
	})
	return { data: data?.data, ...rest }
}

export const prefetchProgramAod = (props: ProgramAodReqModel) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props))
