import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

export interface AodConfigState {
	playbackRate?: number
	volume?: number
	mute?: boolean
}

export const aodConfigState = atom<AodConfigState>({
	key: '#aodConfig',
	default: {
		playbackRate: 1,
		volume: 0.5,
		mute: false,
	},
})

export const useAodConfigControl = () => {
	const [state, setState] = useRecoilState(aodConfigState)

	const setAodConfig = useCallback(
		(value?: AodConfigState) => {
			setState((prev) => ({
				...prev,
				...value,
			}))
		},
		[setState],
	)

	return { aodConfig: state, setAodConfig }
}
