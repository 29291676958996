'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import useHandleAppRefresh from '@hooks/useHandleAppRefresh'
import { Box, Drawer, DrawerProps, styled } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'
import { TouchEvent, useCallback, useEffect, useState } from 'react'

const CloseBtn = styled('button')({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	paddingTop: pxToRem(14),
	paddingBottom: pxToRem(14),
	'&::before': {
		content: '""',
		display: 'block',
		width: pxToRem(42),
		height: pxToRem(4),
		background: color.colGray3,
		borderRadius: pxToRem(2),
	},
})

interface UIDropdownProps extends DrawerProps {
	id?: string
	open: boolean
	onClose: () => void
	children: React.ReactNode
	tempHide?: boolean
	backDrop?: boolean
}
const UIDropdown = ({
	id,
	open,
	onClose,
	children,
	tempHide,
	backDrop = false,
	...props
}: UIDropdownProps) => {
	const handleAppRefresh = useHandleAppRefresh()
	const [position, setPosition] = useState<number>(0)
	const [moved, setMoved] = useState<number>(0)
	const { darkMode } = useDarkModeControl()
	const onTouchStart = (e: TouchEvent) => {
		setPosition(e.changedTouches[0].pageY)
	}
	const onTouchMove = useCallback(
		(e: TouchEvent) => {
			const target = e.target as HTMLButtonElement
			const drawer = target.parentNode?.parentNode as HTMLDivElement
			const touchMove = e.changedTouches[0].pageY - position
			if (drawer) {
				const maxMove = drawer.clientHeight - 50 // Drawer의 높이로 제한
				const limitedMove = Math.min(touchMove, maxMove) // 제한된 이동 거리
				drawer.style.transform = `translateY(${touchMove > 0 ? limitedMove : 0}px)`
				drawer.style.transition = 'none'
			}
			setMoved(touchMove > 0 ? touchMove : 0)
		},
		[position],
	)
	const onTouchEnd = useCallback(
		(e: TouchEvent) => {
			const target = e.target as HTMLButtonElement
			const drawer = target.parentNode?.parentNode as HTMLDivElement
			const closeAction = drawer.clientHeight / 3 < moved
			drawer.style.transition = ''
			if (closeAction) {
				drawer.style.transform = ''
				onClose()
			} else {
				drawer.style.transform = ''
			}
			setPosition(0)
			setMoved(0)
		},
		[position, moved],
	)
	useEffect(() => {
		if (open) {
			handleAppRefresh(false)
			document.body.classList.add('scroll-lock-all')
		}
		return () => {
			handleAppRefresh(true)
			document.body.classList.remove('scroll-lock-all')
		}
	}, [open])
	return (
		<Drawer
			open={open}
			onClose={onClose}
			anchor="bottom"
			sx={{
				zIndex: tempHide ? zIndexes.fullModal - 1 : zIndexes.fullModal,
				'& .MuiDrawer-content': {
					width: '100%',
					background: darkMode ? '#303030' : color.colWhite,
					display: 'flex',
					height: 'auto',
					maxHeight: '80vh',
					borderRadius: `${pxToRem(16)} ${pxToRem(16)} 0 0`,
					borderTop: darkMode ? 0 : `1px solid ${color.colGray3}`,
					bottom: tempHide ? '-100%' : '0',
					overflow: 'hidden',
					transition: 'var(--Drawer-transitionDuration) var(--Drawer-transitionFunction)',
				},
				'& .MuiDrawer-backdrop': {
					backdropFilter: 'none',
					background: !backDrop
						? 'transparent'
						: darkMode
						? 'rgba(0, 0, 0, .4)'
						: 'rgba(0, 0, 0, .7)',
				},
				...props.sx,
			}}
		>
			<UIFlex height="100%" overflow="hidden">
				<CloseBtn
					type="button"
					onClick={onClose}
					onTouchStart={onTouchStart}
					onTouchMove={onTouchMove}
					onTouchEnd={onTouchEnd}
					sx={{
						flexShrink: 0,
						'&::before': {
							background: darkMode ? '#444' : color.colGray3,
						},
					}}
				>
					<UIText readonly>닫기 버튼</UIText>
				</CloseBtn>
				<Box id={id} flexGrow={1} overflow="auto">
					{children}
				</Box>
			</UIFlex>
		</Drawer>
	)
}

export default UIDropdown
