'use client'

import UIFlex from '@components/ui/UIFlex'
import { pxToRem } from '@ui/style/muiTheme'
import { Box, Slider } from '@mui/joy'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import AppAodPlayerControls from './AppAodPlayerControls'
import { useAppAodStates } from '../store/store'
import { DATE_FORMAT, dayjs } from '@utils/date'
import { ProgramAodList, ProgramAodResponseVo } from '@schemas/non-auth'
import UIDropdown from '@components/ui/UIDropdown'
import UIImage from '@components/ui/UIImage'

interface AppAodPlayerProps {
	info: ProgramAodResponseVo
	playAod: ProgramAodList
	paused: boolean
	duration: number
	currentTime: number
	updateCurrentTime: (value: number) => void
	handlePlay: () => void
}
const AppAodPlayer = ({
	info,
	playAod,
	paused,
	duration,
	currentTime,
	updateCurrentTime,
	handlePlay,
}: AppAodPlayerProps) => {
	const [appAodStates, setAppAodStates] = useAppAodStates()
	const onClose = () => {
		setAppAodStates((prev) => ({
			...prev,
			playerOpen: false,
		}))
	}
	const getTime = (time: number) => {
		return `${Math.floor(time / 60)}:${Math.floor(time % 60)
			.toString()
			.padStart(2, '0')}`
	}
	return (
		<UIDropdown
			open={appAodStates.playerOpen}
			onClose={onClose}
			tempHide={appAodStates.playerOpen && appAodStates.playlistOpen}
		>
			<UIFlex height={pxToRem(408)} py={pxToRem(34)} px={pxToRem(32)}>
				<UIFlex
					alignItems="center"
					px={pxToRem(16)}
					sx={{
						img: {
							width: `${pxToRem(216)}!important`,
							height: `${pxToRem(122)}!important`,
						},
						'&>*:not(:first-child)': {
							marginTop: pxToRem(20),
						},
					}}
				>
					{info?.programThumbnailImgUrl && (
						<UIImage src={info.programThumbnailImgUrl ?? ''} isContain alt="" />
					)}
					<UIFlex
						sx={{
							'&>*:not(:first-child)': {
								marginTop: pxToRem(6),
							},
						}}
					>
						<UIText
							fontSize={pxToRem(20)}
							lineHeight={pxToRem(24)}
							fontWeight={700}
							color={color.colBlack}
							lineClamp={1}
							textAlign="center"
						>
							{info?.programName ?? ''}
						</UIText>
						<UIText
							fontSize={pxToRem(15)}
							lineHeight={pxToRem(22)}
							fontWeight={500}
							lineClamp={1}
							textAlign="center"
							color={color.colGray5}
						>
							{playAod?.insertDate &&
								dayjs(playAod?.insertDate).format(
									DATE_FORMAT.DATE_FULL_WITH_WEEKDAY,
								)}
						</UIText>
					</UIFlex>
				</UIFlex>
				<Box marginTop={pxToRem(32)}>
					<Slider
						step={0.001}
						min={0}
						max={duration}
						value={currentTime}
						onChange={(e, value) => updateCurrentTime(value as number)}
						sx={{
							display: 'block',
							position: 'relative',
							width: '100%',
							padding: 0,
							transform: 'none',
							height: pxToRem(6),
							borderRadius: pxToRem(3),
							overflow: 'hidden',
							backgroundColor: 'rgba(0,0,0,0)',
							'.MuiSlider': {
								'&-rail': {
									background: color.colGray3,
									height: pxToRem(6),
									borderRadius: pxToRem(3),
								},
								'&-track': {
									height: pxToRem(6),
									background: color.colMintBlue,
									borderRadius: pxToRem(3),
								},
								'&-thumb': {
									display: 'none',
								},
							},
						}}
					/>
					<UIFlex flexDirection="row" justifyContent="space-between" mt={pxToRem(8)}>
						<UIText
							fontSize={pxToRem(14)}
							lineHeight={pxToRem(20)}
							fontWeight={500}
							color={color.colGray5}
						>
							{getTime(currentTime)}
						</UIText>
						<UIText
							fontSize={pxToRem(14)}
							lineHeight={pxToRem(20)}
							fontWeight={500}
							color={color.colGray5}
						>
							{getTime(duration)}
						</UIText>
					</UIFlex>
				</Box>
				<Box marginTop={pxToRem(32)}>
					<AppAodPlayerControls
						paused={paused}
						currentTime={currentTime}
						updateCurrentTime={updateCurrentTime}
						handlePlay={handlePlay}
					/>
				</Box>
			</UIFlex>
		</UIDropdown>
	)
}

export default AppAodPlayer
