const IconPlayNext = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 16.0051C14 15.1727 14.9244 14.7024 15.5628 15.2099L25.6211 23.2048C26.1263 23.6064 26.1263 24.3936 25.6211 24.7952L15.5628 32.7901C14.9244 33.2976 14 32.8273 14 31.9949V16.0051Z"
				fill="#242424"
			/>
			<rect x="30" y="15" width="4" height="18" rx="1" fill="#242424" />
		</svg>
	)
}

export default IconPlayNext
