interface IconCirclePlayProps {
	isPlaying?: boolean
}
const IconCirclePlay = ({ isPlaying }: IconCirclePlayProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
		>
			{isPlaying ? (
				<>
					<rect width="28" height="28" rx="14" fill="#E4E4E4" />
					<rect
						x="9.40625"
						y="7.875"
						width="3.49967"
						height="12.2489"
						rx="1"
						fill="#242424"
					/>
					<rect
						x="15.093"
						y="7.875"
						width="3.49967"
						height="12.2489"
						rx="1"
						fill="#242424"
					/>
				</>
			) : (
				<>
					<rect width="28" height="28" rx="14" fill="#E4E4E4" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M10 8.82167C10 8.03258 10.8712 7.55437 11.5369 7.97801L19.6742 13.1563C20.2917 13.5493 20.2917 14.4507 19.6742 14.8437L11.5369 20.022C10.8712 20.4456 10 19.9674 10 19.1783V8.82167Z"
						fill="#242424"
					/>
				</>
			)}
		</svg>
	)
}

export default IconCirclePlay
