'use client'

import { atom, useRecoilState, useSetRecoilState } from 'recoil'

interface AppAodStates {
	playerOpen: boolean
	playlistOpen: boolean
}

const initialAppAodStates: AppAodStates = {
	playerOpen: false,
	playlistOpen: false,
}

export const appAodStatesAtom = atom<AppAodStates>({
	key: 'aodStates',
	default: initialAppAodStates,
})

export const useAppAodStates = () => useRecoilState(appAodStatesAtom)

export const useSetAppAodStates = () => useSetRecoilState(appAodStatesAtom)
