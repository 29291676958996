import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

export interface AodState {
	programIdx?: string
	date?: string
	audioIdx?: string
	playAodIdx?: string
}

export const aodState = atom<AodState>({
	key: '#aod',
	default: {
		programIdx: undefined,
		audioIdx: undefined,
		date: undefined,
		playAodIdx: undefined,
	},
})

export const useAodControl = () => {
	const [state, setState] = useRecoilState(aodState)

	const setAod = useCallback(
		(value?: AodState) => {
			setState({
				programIdx: value?.programIdx,
				audioIdx: value?.audioIdx,
				date: value?.date,
			})
		},
		[setState],
	)

	const setPlayAod = useCallback(
		(value?: string) => {
			setState((prev) =>
				value
					? {
							...prev,
							playAodIdx: value,
					  }
					: {},
			)
		},
		[setState],
	)
	return { aod: state, setAod, setPlayAod }
}
