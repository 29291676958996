import { ApiResultVoBoolean } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'

export type StatisticsAodReadResponse = CommonResponse<ApiResultVoBoolean>

export interface StatisticsAodReadReqModel {
	audioIdx: string
}

const path = '/restapi/v1/post/statistics/program/aod-read'
const queryKeyString = (props?: StatisticsAodReadReqModel) =>
	['@statistics/program/aod-read', props] as const

const getQuery = async (props: StatisticsAodReadReqModel, cookieHeader?: string) => {
	const { audioIdx } = props
	const params = new URLSearchParams({
		audioIdx,
	})
	return fetchAPI({ path, params, method: 'POST', cookieHeader })
}

export const queryStatisticsAodRead = (
	props: StatisticsAodReadReqModel,
	cookieHeader?: string,
) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const statisticsAodReadMutationProps = (cookieHeader?: string) => ({
	mutationKey: queryKeyString(),
	mutationFn: (props: StatisticsAodReadReqModel) => getQuery(props, cookieHeader),
})

export const prefetchStatisticsAodRead = (
	props: StatisticsAodReadReqModel,
	cookieHeader?: string,
) => createPrefetchQuery(queryKeyString(props), () => getQuery(props, cookieHeader))
