'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box, styled } from '@mui/joy'
import { ProgramAodList } from '@schemas/non-auth'
import { useProgramAodListMutation } from '@services/api/Program/AodList'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { DATE_FORMAT, dayjs } from '@utils/date'
import { TouchEvent, useCallback, useEffect, useRef, useState } from 'react'
import Icon24Close from 'src/assets/icons/Icon24Close'
import { useAppAodStates } from '../store/store'
import AppAodPlayItem from './AppAodPlayItem'
import { getSelector } from '@utils/getSelector'
import UIDropdown from '@components/ui/UIDropdown'

const ItemBox = styled(Box)({
	paddingTop: pxToRem(12),
	paddingBottom: pxToRem(11),
	borderBottom: `1px solid ${color.colGray3}`,
	'&:first-of-type': {
		paddingTop: pxToRem(11),
		borderTop: `1px solid ${color.colGray3}`,
	},
})

interface AppAodPlaylistProps {
	programIdx: string
	programName: string
	aodList?: Array<ProgramAodList>
	playAodIdx?: string
	playIndex: number
	paused: boolean
	handlePlay: () => void
	setCurrentAod: (value: ProgramAodList) => void
}
const AppAodPlaylist = ({
	programIdx,
	programName,
	aodList,
	playAodIdx,
	playIndex,
	paused,
	handlePlay,
	setCurrentAod,
}: AppAodPlaylistProps) => {
	const [appAodStates, setAppAodStates] = useAppAodStates()
	const [playList, setPlayList] = useState<Array<ProgramAodList>>()
	const [position, setPosition] = useState<number>(0)
	const [moved, setMoved] = useState<number>(0)
	const [exceptAudioIdx, setExceptAudioIdx] = useState<string>()
	const innerRef = useRef<HTMLDivElement>()
	const { mutateAsync: mutateProgramAodList } = useProgramAodListMutation()

	const fillPlayTime = (time: string) => {
		const splitTime = time.split(':').reverse()
		return `${(splitTime[2] ?? '0').toString().padStart(2, '0')}:${splitTime[1]}:${
			splitTime[0]
		}`
	}
	const getAodList = useCallback(
		async (isPrev?: boolean) => {
			const exceptItem = playList?.[isPrev ? playList.length - 1 : 0]
			const list = await mutateProgramAodList({
				programIdx,
				searchDate: dayjs(exceptItem?.insertDate).format(DATE_FORMAT.DATE_AS_NUMBER),
				searchType: isPrev ? 'PRE_LIST' : 'NEXT_LIST',
				exceptAudioIdx: exceptItem?.audioIdx,
			})
			setExceptAudioIdx(!isPrev ? exceptItem?.audioIdx : undefined)
			if (list?.data) {
				setPlayList([
					...(!isPrev ? list?.data : []),
					...(playList ?? []),
					...(isPrev ? list?.data : []),
				])
			}
		},
		[programIdx, playList],
	)
	const onClose = () => {
		setAppAodStates((prev) => ({
			...prev,
			playlistOpen: false,
		}))
	}
	const moveComputed = (x: number): number => {
		return 1 - (1 - x) * (1 - x)
	}
	const scrollBounce = () => {
		const target = getSelector('.aod-list-drawer') as HTMLDivElement
		const { scrollHeight, offsetHeight, scrollTop } = target
		if (scrollTop === 0) {
			return scrollHeight === offsetHeight ? 'zero' : 'start'
		}
		if (scrollHeight - offsetHeight - Math.ceil(scrollTop) <= 0) {
			return 'end'
		}
		return undefined
	}
	const onTouchStart = (e: TouchEvent) => {
		setPosition(e.changedTouches[0].pageY)
	}
	const onTouchMove = useCallback(
		(e: TouchEvent) => {
			const scrollTo = scrollBounce()
			if (scrollTo) {
				const touchMove = e.changedTouches[0].pageY - position
				const moveSize = `${moveComputed(touchMove / window.outerHeight) * 100}px`
				if (
					(['start', 'zero'].includes(scrollTo) && touchMove > 0) ||
					(['end', 'zero'].includes(scrollTo) && touchMove < 0)
				) {
					if (innerRef.current) {
						innerRef.current.style.transition = 'none'
						innerRef.current.style.top = moveSize
					}
				}
			}
		},
		[position],
	)
	const onTouchEnd = useCallback(
		(e: TouchEvent) => {
			if (innerRef.current) {
				if (innerRef.current.style.top) {
					getAodList(parseInt(innerRef?.current?.style?.top ?? '0', 10) < 0)
					innerRef.current.style.transition = ''
					innerRef.current.style.top = ''
				}
			}
			setPosition(0)
			setMoved(0)
		},
		[position, moved],
	)
	const handleAodPlay = useCallback(
		(item: ProgramAodList) => {
			if (item.audioIdx === playAodIdx) {
				handlePlay()
			} else {
				const activeAudio = playList?.find(({ audioIdx }) => audioIdx === item.audioIdx)
				if (activeAudio) {
					setCurrentAod(activeAudio)
				}
			}
		},
		[playAodIdx, playList, handlePlay],
	)

	useEffect(() => {
		if (!playList && aodList) {
			setPlayList(aodList.filter((item, index) => index >= playIndex))
		}
	}, [aodList, playList, playIndex])

	useEffect(() => {
		const target = getSelector('.aod-list-drawer')
		if (exceptAudioIdx && playList && target) {
			const index = playList.findIndex((item) => item.audioIdx === exceptAudioIdx)
			target.scrollTo({
				top: 68 * index,
				behavior: 'instant',
			})
		}
	}, [playList, exceptAudioIdx])

	return (
		<UIDropdown open={appAodStates.playlistOpen} onClose={onClose}>
			<UIFlex height={pxToRem(408)} position="relative" overflow="hidden">
				{/* header */}
				<Box
					px={pxToRem(36)}
					pb={pxToRem(16)}
					flexShrink={0}
					position="relative"
					sx={{
						'.button-close': {
							position: 'absolute',
							left: pxToRem(12),
							top: 0,
						},
					}}
				>
					<button type="button" className="button-close" onClick={onClose}>
						<Icon24Close />
						<UIText readonly>닫기</UIText>
					</button>
					<UIText
						textAlign="center"
						fontSize={pxToRem(18)}
						color={color.colBlack}
						lineHeight={pxToRem(24)}
						fontWeight={700}
					>
						JTBC 뉴스룸
					</UIText>
				</Box>
				{/* body */}
				<Box
					className="aod-list-drawer"
					px={pxToRem(20)}
					flexGrow={1}
					overflow="auto"
					onTouchStart={onTouchStart}
					onTouchMove={onTouchMove}
					onTouchEnd={onTouchEnd}
					sx={{ scrollBehavior: 'smooth' }}
				>
					<Box
						ref={innerRef}
						sx={{ position: 'relative', top: 0, transition: 'top 0.2s' }}
					>
						{playList?.map((item) => (
							<ItemBox
								key={`aod-${item.audioIdx}`}
								className="aod-item"
								onClick={() => handleAodPlay(item)}
							>
								<AppAodPlayItem
									title={dayjs(item.insertDate).format(
										DATE_FORMAT.DATE_FULL_WITH_WEEKDAY,
									)}
									duration={fillPlayTime(item.playTime)}
									isPlaying={item.audioIdx === playAodIdx && !paused}
								/>
							</ItemBox>
						))}
					</Box>
				</Box>
			</UIFlex>
		</UIDropdown>
	)
}

export default AppAodPlaylist
