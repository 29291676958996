interface IconPlaySmProps {
	isPlaying?: boolean
}
const IconPlaySm = ({ isPlaying }: IconPlaySmProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			{isPlaying ? (
				<>
					<rect x="9" y="6.6665" width="5.33333" height="18.6667" rx="1" fill="#242424" />
					<rect
						x="17.6667"
						y="6.6665"
						width="5.33333"
						height="18.6667"
						rx="1"
						fill="#242424"
					/>
				</>
			) : (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9 7.24104C9 6.46925 9.83721 5.98837 10.5039 6.37726L25.5192 15.1362C26.1807 15.5221 26.1807 16.4779 25.5192 16.8638L10.5039 25.6227C9.83721 26.0116 9 25.5308 9 24.759V7.24104Z"
					fill="#242424"
				/>
			)}
		</svg>
	)
}

export default IconPlaySm
