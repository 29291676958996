import { useMutation } from '@tanstack/react-query'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { ApiResultVoListProgramAodList } from '@schemas/non-auth'
import { fetchAPI } from '@utils/http/apiRequest'

export type ProgramAodListResponse = CommonResponse<ApiResultVoListProgramAodList>

export interface ProgramAodListReqModel {
	programIdx: string
	searchDate?: string
	searchType?: 'PRE_LIST' | 'NEXT_LIST'
	exceptAudioIdx?: string
}

const path = '/restapi/v1/get/contents/program/aod-list'
const queryKeyString = (props?: ProgramAodListReqModel) =>
	[`@contents/program/aod-list`, props] as const

const getQuery = async (props: ProgramAodListReqModel) => {
	const { programIdx, searchDate, searchType, exceptAudioIdx } = props
	const params = new URLSearchParams({
		...(programIdx ? { programIdx: String(programIdx) } : {}),
		...(searchDate ? { searchDate: String(searchDate) } : { searchDate: 'null' }),
		...(searchType ? { searchType: String(searchType) } : {}),
		...(exceptAudioIdx ? { exceptAudioIdx: String(exceptAudioIdx) } : {}),
	})
	return fetchAPI({ path, params })
}

export const queryProgramAodList = (props: ProgramAodListReqModel) => ({
	queryKey: [...queryKeyString(props)],
	queryFn: () => getQuery(props),
})

export const useProgramAodListMutation = () => {
	const { data, ...rest } = useMutation<
		ApiResultVoListProgramAodList,
		Error,
		ProgramAodListReqModel
	>({
		mutationKey: queryKeyString(),
		mutationFn: (props: ProgramAodListReqModel) => getQuery(props),
	})
	return { data: data?.data, ...rest }
}

export const prefetchProgramAodList = (props: ProgramAodListReqModel) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props))
