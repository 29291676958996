'use client'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import IconCirclePlay from 'src/assets/icons/IconCirclePlay'

interface AppAodPlayItemProps {
	title: string
	duration: string
	isPlaying?: boolean
}
const AppAodPlayItem = ({ title, duration, isPlaying }: AppAodPlayItemProps) => {
	return (
		<UIFlex
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				button: {
					textAlign: 'left',
				},
				'&>*:not(:first-child)': {
					marginLeft: pxToRem(10),
				},
			}}
		>
			<UIFlex
				sx={{
					'&>*:not(:first-child)': {
						marginTop: pxToRem(4),
					},
				}}
			>
				<UIText
					fontSize={pxToRem(16)}
					color={color.colBlack}
					lineHeight={pxToRem(22)}
					fontWeight={600}
					lineClamp={2}
				>
					{title}
				</UIText>
				<UIText
					fontSize={pxToRem(14)}
					lineHeight={pxToRem(16)}
					fontWeight={500}
					color={color.colGray5}
				>
					{duration}
				</UIText>
			</UIFlex>
			<IconCirclePlay isPlaying={isPlaying} />
			<UIText readonly>재생</UIText>
		</UIFlex>
	)
}

export default AppAodPlayItem
