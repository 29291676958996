'use client'

import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { Box, Modal, ModalDialog } from '@mui/joy'
import { useAodConfigControl } from '@store/aodConfig'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import zIndexes from '@ui/style/zIndexes.theme'
import { useState } from 'react'
import Icon16More from 'src/assets/icons/Icon16More'

const AodViewMoreModal = () => {
	const { aodConfig, setAodConfig } = useAodConfigControl()
	const [open, setOpen] = useState(false)
	const openModal = () => {
		setOpen(true)
	}
	const closeModal = () => {
		setOpen(false)
	}
	const handlePlaybackRate = () => {
		const rateList = [1, 1.5, 2]
		const index = rateList.findIndex((rate) => rate === aodConfig.playbackRate)
		const playbackRate = rateList[(index + 1) % rateList.length]
		setAodConfig({ playbackRate })
	}
	return (
		<Box display={{ xs: 'block', md: 'none' }}>
			<button type="button" className="button-more" onClick={openModal}>
				<Icon16More />
				<UIText readonly>더보기</UIText>
			</button>
			<Modal
				open={open}
				onClose={closeModal}
				sx={{
					zIndex: zIndexes.fullModal,
					'.MuiModal': {
						'&-backdrop': {
							backdropFilter: 'none',
							background: 'rgba(0, 0, 0, 0.7)',
						},
					},
				}}
			>
				<ModalDialog
					sx={{
						borderRadius: pxToRem(8),
						padding: 0,
						gap: 0,
						width: pxToRem(168),
						minWidth: 0,
						'.modalSelect': {
							'&-item': {
								display: 'block',
								paddingY: pxToRem(12),
								textAlign: 'center',
								fontSize: pxToRem(16),
								color: color.colBlack,
								lineHeight: pxToRem(24),
								fontWeight: 500,
								'&+.modalSelect-item': {
									borderTop: `1px solid ${color.colGray3}`,
								},
							},
						},
					}}
				>
					<UILink
						className="modalSelect-item"
						target="_blank"
						href="https://podcasts.apple.com/kr/podcast/jtbc-%EB%89%B4%EC%8A%A4%EB%A3%B8/id807339352"
					>
						팟캐스트 이동
					</UILink>
					<UILink
						className="modalSelect-item"
						target="_blank"
						href="https://www.podbbang.com/channels/6949"
					>
						팟빵 이동
					</UILink>
					<button className="modalSelect-item" type="button" onClick={handlePlaybackRate}>
						배속 설정 ({aodConfig.playbackRate?.toFixed(1)}x)
					</button>
				</ModalDialog>
			</Modal>
		</Box>
	)
}

export default AodViewMoreModal
