import {
	dehydrate,
	InvalidateQueryFilters,
	QueryFilters,
	QueryOptions,
	UseInfiniteQueryOptions,
	type QueryFunction,
	type QueryKey,
} from '@tanstack/react-query'
import { getQueryClient } from './misc/getQueryClient'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryData<F extends (...args: any) => any> = PromiseReturnType<ReturnType<F>>

export const createPrefetchQuery = async <K extends QueryKey, F>(
	queryKey: K,
	queryFn: QueryFunction<F, K>,
) => {
	const queryClient = getQueryClient()
	const cacheData = await queryClient.getQueryData<QueryData<typeof queryFn>>(queryKey)
	if (cacheData) {
		queryClient.invalidateQueries({ queryKey })
	}
	await queryClient.prefetchQuery({
		queryKey,
		queryFn,
		gcTime: 1000 * 3,
		staleTime: 1000 * 3,
	})

	const data = await queryClient.getQueryData<QueryData<typeof queryFn>>(queryKey)
	const dehydratedState = dehydrate(queryClient)

	return { data, dehydratedState }
}

export const createPrefetchInfiniteQuery = async (queryOptions: UseInfiniteQueryOptions) => {
	const { queryKey } = queryOptions
	const queryClient = getQueryClient()
	const cacheData = await queryClient.getQueryData<QueryData<QueryFunction>>(queryKey)
	if (cacheData) {
		queryClient.invalidateQueries({ queryKey })
	}
	await queryClient.prefetchInfiniteQuery(queryOptions)

	const data = await queryClient.getQueryData<QueryData<QueryFunction>>(queryKey)
	const dehydratedState = dehydrate(queryClient)

	return { data, dehydratedState }
}

export const createPrefetchAll = async (apis: { [key: string]: Promise<unknown> }) => {
	const APIKeys = Object.keys(apis)
	const preFetchAll = APIKeys.map(
		(key: string) => Object.getOwnPropertyDescriptor(apis, key)?.value,
	)
	const responseAll = await Promise.all(preFetchAll)
	return responseAll.reduce((accumulator, resItem, index) => {
		return {
			...accumulator,
			[APIKeys[index]]: resItem?.data?.data ?? resItem,
		}
	}, {})
}

export const queryPrefetch = async <K extends QueryKey, F>({
	queryKey,
	queryFn,
}: {
	queryKey: K
	queryFn: QueryFunction<F, K>
}) => {
	const queryClient = getQueryClient()
	await queryClient.prefetchQuery({
		queryKey,
		queryFn,
	})
	const dehydratedState = dehydrate(queryClient)
	const data = queryClient.getQueriesData(dehydratedState.queries as QueryFilters)
	queryClient.invalidateQueries(dehydratedState.queries as InvalidateQueryFilters)
	return { dehydratedState, data }
}

export const queryPrefetchAll = async <K extends QueryKey, F>(queries: Array<QueryOptions>) => {
	const queryClient = getQueryClient()
	const prefetch = async ({ queryKey, queryFn }: QueryOptions) =>
		queryClient.prefetchQuery({
			queryKey: queryKey as QueryKey,
			queryFn,
		})
	await Promise.all(queries.map(prefetch))
	const dehydratedState = dehydrate(queryClient)
	const data = queryClient.getQueriesData(dehydratedState.queries as QueryFilters)
	queryClient.invalidateQueries(dehydratedState.queries as InvalidateQueryFilters)
	return {
		queryClient,
		dehydratedState,
		data: data.map(([, queryData]) => queryData),
	}
}
