const Icon24Close = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path d="M15.5 5L8.5 12L15.5 19" stroke="#242424" strokeWidth="2" />
		</svg>
	)
}

export default Icon24Close
