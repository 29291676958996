import { useLocalStorage } from '@hooks/useLocalStorage'
import { useNotification } from '@lib/notification'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

export interface ArticleSizeState {
	articleSize?: 'M' | 'L' | 'XL'
}

export const articleSizeState = atom<ArticleSizeState>({
	key: '#articleSize',
	default: {
		articleSize: 'M',
	},
})

export const useArticleSizeControl = () => {
	const { notify } = useNotification()
	const [state, setState] = useRecoilState(articleSizeState)
	const [, setLocalArticleSize] = useLocalStorage('article_size')
	const nextArticleSize = useCallback(() => {
		const articleSizeList = ['M', 'L', 'XL'] as const
		const sizeNameList = {
			M: "'보통'으로",
			L: "'크게'로",
			XL: "'아주 크게'로",
		}
		const index = articleSizeList.findIndex((size) => size === state.articleSize)
		const articleSize = articleSizeList[(index + 1) % articleSizeList.length]
		notify(`글자 크기가 ${sizeNameList[articleSize]} 설정되었습니다.`)
		setLocalArticleSize(articleSize)
		setState({ articleSize })
	}, [state.articleSize, setState])

	const setArticleSize = useCallback(
		(value?: 'M' | 'L' | 'XL') => {
			if (value) {
				setState({ articleSize: value })
				setLocalArticleSize(value)
			} else {
				setState({ articleSize: 'M' })
				setLocalArticleSize('M')
			}
		},
		[setState],
	)

	return { articleSize: state.articleSize, nextArticleSize, setArticleSize }
}
