interface IconPlayProps {
	isPlaying?: boolean
}
const IconPlay = ({ isPlaying }: IconPlayProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			{isPlaying ? (
				<>
					<rect x="13.5" y="10" width="8" height="28" rx="1" fill="#242424" />
					<rect x="26.5" y="10" width="8" height="28" rx="1" fill="#242424" />
				</>
			) : (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13 10.7662C13 9.9889 13.848 9.50878 14.5145 9.9087L36.5708 23.1425C37.2182 23.5309 37.2182 24.4691 36.5708 24.8575L14.5145 38.0913C13.848 38.4912 13 38.0111 13 37.2338V10.7662Z"
					fill="#242424"
				/>
			)}
		</svg>
	)
}

export default IconPlay
