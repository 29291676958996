export const getSelectorAll = (selector: string) => {
	const selectorAll = global.document.querySelectorAll(selector)
	if ((selectorAll?.length ?? 0) === 0) {
		return undefined
	}
	return selectorAll
}
export const getSelector = (selector: string) => {
	return global.document.querySelector(selector)
}
