const Icon32Playlist = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<rect
				x="6.95"
				y="7.45"
				width="18.1"
				height="0.9"
				fill="#242424"
				stroke="#242424"
				strokeWidth="0.9"
			/>
			<rect
				x="6.95"
				y="14.2508"
				width="18.1"
				height="0.9"
				fill="#242424"
				stroke="#242424"
				strokeWidth="0.9"
			/>
			<rect
				x="6.95"
				y="21.0516"
				width="18.1"
				height="0.9"
				fill="#242424"
				stroke="#242424"
				strokeWidth="0.9"
			/>
			<path
				d="M20.7354 17.0476C19.7355 16.4851 18.5 17.2077 18.5 18.3549V25.6451C18.5 26.7923 19.7355 27.5149 20.7354 26.9524L27.2155 23.3074C28.235 22.7339 28.235 21.2661 27.2155 20.6926L20.7354 17.0476Z"
				fill="#242424"
				stroke="white"
				strokeWidth="2"
			/>
		</svg>
	)
}

export default Icon32Playlist
