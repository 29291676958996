/* eslint-disable consistent-return */
import { QueryClient } from '@tanstack/react-query'

function handleError(error: unknown, context: string) {
	if (error instanceof Error) {
		const errorMessage = error.message

		try {
			const errorObj = JSON.parse(errorMessage)
			console.log('errorObj', errorObj)
			if (errorObj.status === 502) {
				console.error(`502 Bad Gateway error occurred in ${context}`)
				return {
					resultCode: '00',
					data: null,
				}
			}
		} catch (e) {
			console.error('Error parsing error message:', e)
			return {
				resultCode: '00',
				data: null,
			}
		}
	}
}

export const getQueryClient = () =>
	new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 1000 * 3,
				gcTime: 1000 * 3,
				throwOnError: true,
			},
		},
	})
