interface IconVolumeProps {
	isMuted?: boolean
}
const IconVolume = ({ isMuted }: IconVolumeProps) => {
	return (
		<p>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="22"
				height="18"
				viewBox="0 0 22 18"
				fill="none"
			>
				<path
					d="M1.5 12.5V5.5H5.41304L10.5 2V16L5.41304 12.5H1.5Z"
					stroke="#242424"
					strokeWidth="1.8"
				/>
				{isMuted ? (
					<path
						d="M14.5 5.5L21.5 12.5M21.5 5.5L14.5 12.5"
						stroke="#242424"
						strokeWidth="1.8"
					/>
				) : (
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14 17.8446C18.4454 17.3484 21.9016 13.5778 21.9016 9.00002C21.9016 4.42221 18.4454 0.65165 14 0.155396V1.96967C17.4485 2.455 20.1016 5.41768 20.1016 9.00002C20.1016 12.5824 17.4485 15.545 14 16.0304V17.8446ZM14 13.2858C15.9483 12.833 17.4 11.086 17.4 8.99992C17.4 6.91385 15.9483 5.16684 14 4.71405V6.59918C14.9396 6.99101 15.6 7.91834 15.6 8.99992C15.6 10.0815 14.9396 11.0088 14 11.4007V13.2858Z"
						fill="#242424"
					/>
				)}
			</svg>
		</p>
	)
}

export default IconVolume
