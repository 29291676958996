'use client'

import { ApiResultVoBoolean } from '@schemas/non-auth'
import { useGetCookieHeader } from '@store/cookieHeader'
import { useMutation } from '@tanstack/react-query'
import { StatisticsAodReadReqModel, statisticsAodReadMutationProps } from '.'

export const useStatisticsAodReadMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<ApiResultVoBoolean, Error, StatisticsAodReadReqModel>(
		statisticsAodReadMutationProps(cookieHeader),
	)
	return { data: data?.data, ...rest }
}
